"use client";

import {
    ISearchEventOptions,
    ISearchOptions,
    ISportFeedResponse,
    ITopLeagueFeedResponse,
    ICategoryFeedResponse,
    ITournamentFeedResponse,
    IEventFeedResponse,
} from "@finbackoffice/websocket-client";
import { useCallback, useContext } from "react";
import { sortAlphabeticallyByProp, sortSports } from "@finbackoffice/fe-core";
import { WebsocketContext } from "@finbackoffice/site-core";
import { EventStatus } from "@finbackoffice/enums";

export enum FeedTypeEnum {
    "live" = "live",
    "prematch" = "prematch",
    "results" = "results",
}

export interface ISocketStorage {
    getTopLeagues: () => ITopLeagueFeedResponse[];
    getCategoryById: (id: number) => ICategoryFeedResponse | null | undefined;
    getTournaments: (categoryId: number, options?: ISearchOptions) => ITournamentFeedResponse[];
    getEvents: (tournamentId: number, options?: ISearchEventOptions) => IEventFeedResponse[];
    getEventsBySportId: (sportId: number) => IEventFeedResponse[];
    getSportById: (id: number) => ISportFeedResponse | null | undefined;
    getCategories: (sportId: number, options?: ISearchOptions) => ICategoryFeedResponse[];
    getSports: (options?: ISearchOptions) => ISportFeedResponse[];
    doesTournamentExist: (tournamentId: number, categoryId: number) => boolean;
    getSportBySlug: (slug: string) => ISportFeedResponse | null | undefined;
    hasCategory: (categoryId: number, sportId: number) => boolean;
    getTournamentById: (id: number) => ITournamentFeedResponse | null | undefined;
    getDefaultLiveGameParams: () =>
        | {
              sportSlug: string;
              categoryId: number;
              gameId: number;
          }
        | undefined;
    getDefaultSport: () => ISportFeedResponse;
    getLiveEvent: (id: number) => IEventFeedResponse | undefined;
    connected: boolean;
}

export const useSocketStorage = (feed: FeedTypeEnum): ISocketStorage => {
    const { socketClient, connected } = useContext(WebsocketContext);

    const getSports = useCallback(
        (options?: ISearchOptions) => {
            switch (feed) {
                case FeedTypeEnum.live:
                    return socketClient.getLiveSports(options).sort(sortSports);
                case FeedTypeEnum.prematch:
                    return socketClient.getPrematchSports(options).sort(sortSports);
                case FeedTypeEnum.results:
                    return socketClient.getResultsSports(options).sort(sortSports);
                default:
                    return [];
            }
        },
        [feed, socketClient],
    );

    const getSportById = useCallback(
        (id: number) => {
            switch (feed) {
                case FeedTypeEnum.live:
                    return socketClient.getLiveSport(id);
                case FeedTypeEnum.prematch:
                    return socketClient.getPrematchSport(id);
                case FeedTypeEnum.results:
                    return socketClient.getResultsSport(id);
                default:
                    return null;
            }
        },
        [feed, socketClient],
    );

    const getSportBySlug = useCallback(
        (slug: string) => {
            switch (feed) {
                case FeedTypeEnum.live:
                    return socketClient.getLiveSports()?.find((sport) => sport.slug === slug);
                case FeedTypeEnum.prematch:
                    return socketClient.getPrematchSports()?.find((sport) => sport.slug === slug);
                case FeedTypeEnum.results:
                    return socketClient.getResultsSports()?.find((sport) => sport.slug === slug);
                default:
                    return null;
            }
        },
        [feed, socketClient],
    );

    const getCategories = useCallback(
        (sportId: number, options?: ISearchOptions) => {
            switch (feed) {
                case FeedTypeEnum.live:
                    return socketClient
                        .getLiveCategories(sportId, options)
                        .sort(sortAlphabeticallyByProp("name"));
                case FeedTypeEnum.prematch:
                    return socketClient
                        .getPrematchCategories(sportId, options)
                        .sort(sortAlphabeticallyByProp("name"));
                case FeedTypeEnum.results:
                    return socketClient
                        .getResultsCategories(sportId, options)
                        .sort(sortAlphabeticallyByProp("name"));
                default:
                    return [];
            }
        },
        [feed, socketClient],
    );

    const getCategoryById = useCallback(
        (id: number) => {
            switch (feed) {
                case FeedTypeEnum.live:
                    return socketClient.getLiveCategory(id);
                case FeedTypeEnum.prematch:
                    return socketClient.getPrematchCategory(id);
                default:
                    return null;
            }
        },
        [feed, socketClient],
    );

    const hasCategory = useCallback(
        (categoryId: number, sportId: number): boolean => {
            switch (feed) {
                case FeedTypeEnum.live:
                    return getSportById(sportId)?.categories.indexOf(categoryId) !== -1;
                case FeedTypeEnum.prematch:
                    return getSportById(sportId)?.categories.indexOf(categoryId) !== -1;
                default:
                    return false;
            }
        },
        [feed, getSportById],
    );

    const getTournaments = useCallback(
        (categoryId: number, options?: ISearchOptions) => {
            switch (feed) {
                case FeedTypeEnum.live:
                    return socketClient.getLiveTournaments(categoryId, options);
                case FeedTypeEnum.prematch:
                    return socketClient.getPrematchTournaments(categoryId, options);
                case FeedTypeEnum.results:
                    return socketClient.getResultsTournaments(categoryId, options);
                default:
                    return [];
            }
        },
        [feed, socketClient],
    );

    const getTournamentById = useCallback(
        (id: number) => {
            switch (feed) {
                case FeedTypeEnum.live:
                    return socketClient.getLiveTournament(id);
                case FeedTypeEnum.prematch:
                    return socketClient.getPrematchTournament(id);
                default:
                    return null;
            }
        },
        [feed, socketClient],
    );

    const doesTournamentExist = useCallback(
        (tournamentId: number, categoryId: number): boolean => {
            switch (feed) {
                case FeedTypeEnum.live:
                    return getCategoryById(categoryId)?.tournaments.indexOf(tournamentId) !== -1;
                case FeedTypeEnum.prematch:
                    return getCategoryById(categoryId)?.tournaments.indexOf(tournamentId) !== -1;
                default:
                    return false;
            }
        },
        [feed, getCategoryById],
    );

    const getEvents = useCallback(
        (tournamentId: number, options?: ISearchEventOptions) => {
            switch (feed) {
                case FeedTypeEnum.live:
                    return socketClient.getLiveEvents(
                        tournamentId,
                        options || { statuses_to_exclude: [EventStatus.NotActive] },
                    );
                case FeedTypeEnum.results:
                    return socketClient.getResultsEvents(tournamentId, options);
                default:
                    return [];
            }
        },
        [feed, socketClient],
    );

    const getEventsBySportId = useCallback(
        (sportId: number) => {
            switch (feed) {
                case FeedTypeEnum.prematch:
                    return socketClient.getPrematchEventsBySportId(sportId);
                default:
                    return [];
            }
        },
        [feed, socketClient],
    );

    const getTopLeagues = useCallback(() => {
        switch (feed) {
            case FeedTypeEnum.live:
                return socketClient.getLiveTopLeagues();
            case FeedTypeEnum.prematch:
                return socketClient.getPrematchTopLeagues();
            default:
                return [];
        }
    }, [feed, socketClient]);

    const getDefaultLiveGameParams = useCallback(() => {
        let params;
        const firstSport = getSports()[0];
        if (firstSport) {
            const firstCategoryId = getCategories(firstSport.id)[0].id;
            const firstTournament = getTournaments(firstCategoryId)[0];
            const firstGameId = firstTournament?.events[0];

            if (firstCategoryId && firstGameId) {
                params = {
                    sportSlug: firstSport.slug,
                    categoryId: firstCategoryId,
                    gameId: firstGameId,
                };
            }
        }

        return params;
    }, [getCategories, getSports, getTournaments]);

    const getDefaultSport = useCallback(() => {
        return getSports()[0];
    }, [getSports]);

    const getLiveEvent = useCallback((id: number) => socketClient.getLiveEvent(id), [socketClient]);

    return {
        getTopLeagues,
        getCategoryById,
        getTournaments,
        getEvents,
        getEventsBySportId,
        getSportById,
        getCategories,
        getSports,
        doesTournamentExist,
        getSportBySlug,
        hasCategory,
        getTournamentById,
        getDefaultLiveGameParams,
        getDefaultSport,
        getLiveEvent,
        connected,
    };
};
