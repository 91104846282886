"use client";

import classNames from "classnames";
import { FC, HTMLProps, SyntheticEvent, useEffect, useRef } from "react";
import Loading from "components/base/loading/Loading";
import styles from "./button.module.sass";

interface IProps {
    type: string;
    onClick?: (e: SyntheticEvent) => void;
    className?: any;
    variant?: "primary" | "secondary" | "thirdary" | "custom";
    icon?: string;
    loading?: boolean;
    scrollIntoView?: boolean;
}

const Button: FC<IProps & HTMLProps<HTMLButtonElement>> = ({
    type,
    onClick,
    className,
    variant,
    icon,
    children,
    loading,
    scrollIntoView,
    ...rest
}) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (scrollIntoView) {
            buttonRef.current?.scrollIntoView({
                behavior: "smooth",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <button
            ref={buttonRef}
            type={type === "button" ? "button" : "submit"}
            className={classNames(
                styles.button,
                variant !== "custom" && styles.baseButton,
                variant && styles[variant],
                className,
                (rest.disabled || loading) && styles.disabled,
                icon && styles[icon],
            )}
            onClick={onClick}
            disabled={loading}
            {...rest}>
            {loading ? <Loading /> : children}
        </button>
    );
};

export default Button;
