"use client";

import { TranslationScopes } from "@finbackoffice/enums";
import {
    ClientBFFContext,
    getTranslations,
    I18nContext,
    IDeviceType,
    LocaleContext,
    useRuntimeConfig,
} from "@finbackoffice/site-core";
import { useContext, useEffect, useState } from "react";
// refactor

// @TODO move this

/* type IGetTranslationsConfig = {
    client: ClientBFFClient;
    request: IGetUserInterfaceRequest;
    // includeLocalTranslations: boolean;
    deviceType: IDeviceType;
    localOrigin?: string;
};

 async function getTranslations(config: IGetTranslationsConfig): Promise<IUserInterfaceMapResponse> {
    const { client, request, deviceType } = config;
    const translations =
        deviceType === "mobile"
            ? await client.getMobileTranslations(request)
            : await client.getDesktopTranslations(request);

    return translations;
} */

export const useLoadI18nextResource = (
    value: TranslationScopes,
    deviceType: IDeviceType = "mobile",
): boolean => {
    const VERSION = useRuntimeConfig("VERSION");
    const client = useContext(ClientBFFContext);
    const { locale } = useContext(LocaleContext);
    const { i18n } = useContext(I18nContext);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        async function load() {
            if (i18n.hasResourceBundle(locale, value)) {
                setIsReady(true);
                return;
            }

            const response = await getTranslations({
                client,
                request: {
                    language: locale,
                    scopes: [value],
                },
                deviceType,
                includeLocalTranslations: VERSION === "local",
            });

            i18n.addResourceBundle(locale, value, response[value]);
            setIsReady(true);
        }

        void load();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isReady;
};
