"use client";

import { usePathnameWithLocal, usePrematchUpcomingWidgetConfig } from "@finbackoffice/site-core";
import { HoursRange } from "@finbackoffice/websocket-client";
import {
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    createContext,
    useMemo,
    useState,
} from "react";
import { RouteNames } from "utils/constants";

type CalendarDateState = {
    from: number;
    to: number;
};

type IPrematchContext = {
    calendarDate: CalendarDateState | null;
    hoursRange: HoursRange;
    gameDoesNotExist: boolean;
    setHoursRange: Dispatch<SetStateAction<HoursRange>>;
    setGameDoesNotExist: Dispatch<SetStateAction<boolean>>;
    setCalendarDate: Dispatch<SetStateAction<CalendarDateState | null>>;
};

export const PrematchContext = createContext<IPrematchContext>(null as any);

export const PrematchProvider: FC<PropsWithChildren> = ({ children }) => {
    const { pathnameValues, pathname } = usePathnameWithLocal();
    const isHome = pathnameValues.length === 0;
    const isPrematchUpcoming = pathname?.includes(`/${RouteNames.PREMATCH}/${RouteNames.UPCOMING}`);

    const prematchUpcomingWidgetConfig = usePrematchUpcomingWidgetConfig();
    const defaultHoursRange = useMemo(() => {
        if ((isHome || isPrematchUpcoming) && prematchUpcomingWidgetConfig) {
            return prematchUpcomingWidgetConfig.timeRange;
        }

        return HoursRange.ALL;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [hoursRange, setHoursRange] = useState<HoursRange>(defaultHoursRange);
    const [calendarDate, setCalendarDate] = useState<CalendarDateState | null>(null);
    const [gameDoesNotExist, setGameDoesNotExist] = useState(false);

    const value = useMemo(
        () => ({
            hoursRange,
            gameDoesNotExist,
            calendarDate,
            setHoursRange,
            setGameDoesNotExist,
            setCalendarDate,
        }),
        [calendarDate, gameDoesNotExist, hoursRange],
    );

    return <PrematchContext.Provider value={value}>{children}</PrematchContext.Provider>;
};
