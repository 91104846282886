"use client";

import { createContext, FC, PropsWithChildren, useMemo } from "react";

type CommonTranslationsContextType = {
    translations: Record<string, any>;
};

export const CommonTranslationsContext = createContext({} as CommonTranslationsContextType);

export const CommonTranslationsProvider: FC<
    PropsWithChildren & { translations: Record<string, any> }
> = (props) => {
    const value = useMemo(() => ({ translations: props.translations }), [props.translations]);
    return (
        <CommonTranslationsContext.Provider value={value}>
            {props.children}
        </CommonTranslationsContext.Provider>
    );
};
