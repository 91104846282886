"use client";

import { Currency } from "@finbackoffice/enums";
import { FC, useContext, useMemo } from "react";
import classNames from "classnames";
import { ICurrencyFormatConfig, DigitSeparators } from "@finbackoffice/fe-core";
import { ConfigContextV2 } from "@finbackoffice/site-core"; // refactor
import styles from "./currency-formater.module.sass";

type IFormatAmount = {
    format: ICurrencyFormatConfig | null;
    amount: string;
    withCode?: boolean;
    withSymbol?: boolean;
};

export function formatAmount({
    format,
    amount,
    withCode = true,
    withSymbol,
}: IFormatAmount): string {
    if (format) {
        const fixedPointAmount = parseFloat(amount).toFixed(format.decimal_digits);

        const partials: string[] = fixedPointAmount.split(".");
        let int = partials[0];
        const float =
            partials.length > 1 ? DigitSeparators[format.decimal_separator] + partials[1] : "";

        const rgx = /(\d+)(\d{3})/;

        if (DigitSeparators[format.thousands_separator]) {
            while (rgx.test(int)) {
                int = int.replace(rgx, `$1${DigitSeparators[format.thousands_separator]}$2`);
            }
        }

        const number = `${int}${float}`;

        return `${withSymbol && format.symbol ? format.symbol : ""}${
            withSymbol ? ` ` : ""
        }${number}${withCode && format.code ? ` ${format.code}` : ""}`;
    }
    return amount;
}

type IProps = {
    currency: Currency | "";
    amount: string;
    withCode?: boolean;
    withSymbol?: boolean;
    testId?: string;
    configOverride?: Partial<ICurrencyFormatConfig>;
};

export const CurrencyFormatter: FC<IProps> = ({
    currency,
    amount,
    withCode = true,
    withSymbol,
    testId,
    configOverride,
}) => {
    const { siteConfigReady, siteCurrencyFormat } = useContext(ConfigContextV2);
    const format: ICurrencyFormatConfig | null = useMemo(
        () =>
            siteCurrencyFormat
                ? {
                      ...siteCurrencyFormat?.[currency],
                      ...configOverride,
                  }
                : null,
        [configOverride, currency, siteCurrencyFormat],
    );

    const renderAmount = useMemo(
        () => formatAmount({ format, amount, withCode, withSymbol: withSymbol || false }),
        [amount, format, withCode, withSymbol],
    );

    return siteConfigReady ? (
        <span
            data-testid={testId}
            className={classNames(
                styles.currency,
                withSymbol &&
                    format &&
                    (format.symbol
                        ? "currency-with-symbol"
                        : `base-icon-currency-${currency?.toLowerCase()}`),
            )}>
            {renderAmount}
        </span>
    ) : null;
};
