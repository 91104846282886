"use client";

import {
    ConfigContextV2,
    AuthContext,
    UserAccountContextV2,
    useRuntimeConfig,
    ModalsContextV2,
} from "@finbackoffice/site-core";
import { useParams, useRouter } from "next/navigation";
import { useContext, useCallback } from "react";
import { NotificationContext } from "contexts";
import { RouteNames } from "utils/constants";

export const useCasinoMethods = () => {
    const router = useRouter();
    const params = useParams();
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { siteDefaultCurrency } = useContext(ConfigContextV2);
    const { notice } = useContext(NotificationContext);
    const { isUserLoggedIn } = useContext(AuthContext);
    const { currentWalletId, currentCurrency } = useContext(UserAccountContextV2);
    const { loginModalRef } = useContext(ModalsContextV2);

    const fiatEnabled = COMMON_SITE_CONFIGS.wallet.fiat;
    const defaultCurrency = !fiatEnabled
        ? COMMON_SITE_CONFIGS.wallet._crypto.default
        : siteDefaultCurrency;

    const navigateToGame = useCallback(
        (gameId: string, isSlots: boolean, forFun = false) => {
            let subPath = "";
            if (isSlots) {
                subPath = RouteNames.SLOTS;
            } else {
                subPath = RouteNames.LIVE_CASINO;
            }

            if (subPath) {
                router.push(
                    `/${params?.lang}/${RouteNames.CASINO}/${subPath}/${gameId}?forFun=${forFun}`,
                );
            }
        },
        [params?.lang, router],
    );

    const handlePlayNow = useCallback(
        (gameId: string, isSlots: boolean) => {
            if (isUserLoggedIn) {
                if (currentWalletId) {
                    navigateToGame(gameId, isSlots);
                } else {
                    notice(
                        {
                            type: "warning",
                            title: "Warning",
                            message: `Sorry, but it seems you don't have any ${currentCurrency} in your balance. Please select another currency or the ${defaultCurrency}.`,
                        },
                        "modal",
                    );
                }
            } else {
                loginModalRef.current?.open();
            }
        },
        [
            isUserLoggedIn,
            currentWalletId,
            navigateToGame,
            notice,
            currentCurrency,
            defaultCurrency,
            loginModalRef,
        ],
    );

    const handlePlayForFun = useCallback(
        (gameId: string, isSlots: boolean) => {
            navigateToGame(gameId, isSlots, true);
        },
        [navigateToGame],
    );

    return { handlePlayForFun, handlePlayNow };
};
