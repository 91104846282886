"use client";

import {
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    createContext,
    useMemo,
    useState,
} from "react";
import { useDate } from "hooks";

interface ISportResultsContext {
    activeDate: Date;
    setActiveDate: Dispatch<SetStateAction<Date>>;
    days: Date[];
}

export const SportResultsContext = createContext<ISportResultsContext>(null as any);

export const SportResultsProvider: FC<PropsWithChildren> = ({ children }) => {
    const { subDays } = useDate();
    const days = useMemo(() => {
        const result = new Array(6).fill(null).map((_val, index) => {
            const date = subDays(Date.now(), index + 1);
            return date;
        });

        result.unshift(new Date(Date.now()));

        return result;
    }, [subDays]);
    const [activeDate, setActiveDate] = useState(days[0]);

    const value = useMemo(
        () => ({
            days,
            activeDate,
            setActiveDate,
        }),
        [activeDate, days],
    );

    return <SportResultsContext.Provider value={value}>{children}</SportResultsContext.Provider>;
};
