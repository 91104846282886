"use client";

import { ProvidersEnum, RequestError, CasinoGameTypeEnum } from "@finbackoffice/fe-core";
import {
    UseCasinoGamesHookOptions,
    useCasinoGames as useCoreCasinoGames,
} from "@finbackoffice/site-core";
import { useCallback, useEffect, useMemo } from "react";
import { CasinoGameTag } from "@finbackoffice/enums";
import { useNotification } from "hooks";

export const useCasinoGames = (
    gameTypes: CasinoGameTypeEnum[],
    tag: CasinoGameTag | "",
    provider: ProvidersEnum | "All",
    options?: UseCasinoGamesHookOptions,
) => {
    const { notification, setNotification, clearNotification } = useNotification();

    const onErrorHandler = useCallback(
        (error: RequestError) => {
            setNotification({
                type: "error",
                error: error?.error,
                message: error?.message,
            });
        },
        [setNotification],
    );
    const optionsMemo = useMemo(
        () => ({ ...options, mobile: true, onError: onErrorHandler }),
        [onErrorHandler, options],
    );
    const { casinoGamesState, pagination, setPagination, hasMore } = useCoreCasinoGames(
        gameTypes,
        tag,
        provider,
        optionsMemo,
    );

    useEffect(() => {
        if (casinoGamesState.loading) {
            clearNotification();
        } else if (!casinoGamesState.games.length) {
            setNotification({
                type: "info",
                error: "",
                message: "There is no games at this moment!",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [casinoGamesState.loading]);

    return {
        casinoGamesState,
        notification,
        pagination,
        setPagination,
        hasMore,
    };
};
