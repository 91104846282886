"use client";

import { usePathnameWithLocal } from "@finbackoffice/site-core";
import { FeedTypeEnum } from "hooks";

export const useGetCurrentFeedType = (): FeedTypeEnum => {
    const { pathnameValues } = usePathnameWithLocal();

    return pathnameValues[1] as unknown as FeedTypeEnum;
};
