"use client";

import { useEffect, useRef } from "react";

export const useScrollToSelectedSport = <T extends { id?: number; sport_id?: number }>(
    activeSportId: number | null,
    sports: T[],
) => {
    const containerRef = useRef<HTMLDivElement>(null);

    // scroll to selected sport only once, on mount
    useEffect(() => {
        if (activeSportId) {
            const selectedSportIndex = sports.findIndex((sport) => {
                return (sport.id || sport.sport_id) === activeSportId;
            });
            const width =
                containerRef.current?.children &&
                containerRef.current?.children[selectedSportIndex] &&
                containerRef.current.children[selectedSportIndex].getClientRects()[0].width;

            containerRef.current?.scrollTo({
                left: selectedSportIndex * (width || 0),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        containerRef,
    };
};
